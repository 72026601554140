.policy-container {
  display: flex;
  flex-direction: column;
  width: 0%;
  height: 100%;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgb(236, 245, 254);
  z-index: 8;
  transition: 0.5s;
  position: fixed;
  overflow-y: scroll;
}
.policy-container::-webkit-scrollbar {
  display: none;
}
.policy-container h3 {
  font-size: 15px;
}
.backbtn-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgb(175, 164, 164);
}
.backbtn-box button {
  background-color: transparent;
  border: none;
}
.dark-backbtn-box {
  color: #9fb2d3 !important;
  border-bottom: 1px solid #18202e !important;
}
.dark-backbtn-box button {
  color: #9fb2d3 !important;
}
.dark-backbtn-box span {
  color: #9fb2d3 !important;
}
.dark-policy-container {
  background-color: #0c0d18 !important;
}
.dark-policy-container h1 {
  color: #9fb2d3 !important;
}
.dark-policy-container h3 {
  color: #9fb2d3 !important;
}
.dark-policy-container p {
  color: #9fb2d3 !important;
}
.discdetails-container {
  display: flex;
}
.discdetails-box {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: auto;
  justify-content: space-between;
}
.itemdesc-row p {
  font-size: 0.7rem;
  color: #2c4064;
}

@media screen and (min-width: 950px) and (max-width: 1250px) {
  .policy-size {
    width: 80% !important;
  }
}

@media screen and (min-width: 200px) and (max-width: 949px) {
  .policy-size {
    width: 100% !important;
  }
  .item-title {
    justify-content: center;
  }
  .itemdesc-row p {
    text-align: center;
  }
}
