.cart-container {
  display: flex;
  flex-direction: column;
  width: 0%;
  height: 100%;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgb(236, 245, 254);
  z-index: 11;
  transition: 0.5s;
  position: fixed;
  overflow-y: scroll;
}
.dark-cart-container {
  background-color: #0c0d18 !important;
  color: #9fb2d3 !important;
}
.cart-container::-webkit-scrollbar {
  display: none;
}
.cart-items {
  display: flex;
  width: 100%;
}
.backbtn-box {
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid rgb(209, 196, 196) !important;
}
.dark-backbtn-box {
  color: #9fb2d3 !important;
  border-bottom: 1px solid #18202e !important;
}
.dark-backbtn-box button {
  color: #9fb2d3 !important;
}
.backbtn-box button {
  background-color: transparent;
  border: none;
}
.cartdetails-box {
  display: flex;
  flex-direction: column;
}
.cart-spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.cstm-spinner{
  height: 6rem;
  width: 6rem;
}
.cart-quantity {
  display: flex;
  align-items: flex-start;
}
.order-container {
  display: flex;
}
.order-details {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.order-summary {
  display: flex;
  flex-direction: column;
  border: 1px solid rgb(209, 196, 196);
  border-radius: 4px;
}
.dark-order-summary {
  border: 1px solid #18202e !important;
}
.dark-order-summary h4 {
  color: #9fb2d3 !important;
}
.dark-order-summary h5 {
  color: #9fb2d3 !important;
}
.dark-order-summary span {
  color: #9fb2d3 !important;
}
.summary-title {
  display: flex;
  align-items: flex-start;
}
.summary-title h4 {
  font-size: 20px;
  color: #2c4064;
}
.summary-details {
  display: flex;
  justify-content: space-between;
}
.summary-details h5 {
  font-size: 15px;
  color: #2c4064;
}
.summary-details h4 {
  font-size: 20px;
  font-weight: bold;
  color: #2c4064;
}

.order-information {
  display: flex;
  flex-direction: column;
  border: 1px solid rgb(209, 196, 196);
  border-radius: 4px;
}
.dark-order-information {
  border: 1px solid #18202e !important;
}
.dark-order-information h4 {
  color: #9fb2d3 !important;
}
.dark-order-information h5 {
  color: #9fb2d3 !important;
}
.dark-order-information input {
  background-color: #151620 !important;
  border: 1px solid #18202e !important;
}
.dark-order-information input::placeholder {
  color: #6d7a91 !important;
}
.dark-order-information select {
  background-color: #151620 !important;
  border: 1px solid #18202e !important;
  color: #6d7a91 !important;
}

.order-options {
  display: flex;
  justify-content: space-between;
}
.order-info1 {
  display: flex;
  flex-direction: column;
}
.order-info1 input {
  width: 100%;
  border: 1px solid rgb(209, 196, 196);
  border-radius: 4px;
}
.order-info1 h5 {
  font-size: 15px;
  color: #2c4064;
}

.order-info2 h5 {
  font-size: 15px;
  color: #2c4064;
}
.order-info2 {
  display: flex;
  flex-direction: column;
}
.order-info2 input {
  width: 60%;
  border: 1px solid rgb(209, 196, 196);
  border-radius: 4px;
}

.promo-div {
  display: flex;
  justify-content: space-between;
}
.first-name {
  width: 80% !important;
}
.order-options h4 {
  font-size: 20px;
  color: #2c4064;
}
.order-name h5 {
  font-size: 15px;
  color: #2c4064;
}
.phoneno-box {
  display: flex;
  justify-content: space-between;
}
.confirm-btn {
  border: none;
  color: white;
  background-image: linear-gradient(rgb(245, 24, 171), rgb(67, 129, 245));
}
.promo-btn {
  width: 30%;
  background-image: linear-gradient(
    to right,
    rgb(245, 24, 171),
    rgb(67, 129, 245)
  );
  border: none;
  color: white;
}
.checkout-btn {
  width: 100%;
  background-image: linear-gradient(
    to right,
    rgb(245, 24, 171),
    rgb(67, 129, 245)
  );
  border: none;
  color: white;
}
.code-box {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #18202e;
}
.payment-btn {
  width: 100%;
  border: 1px solid rgb(209, 196, 196);
}

@media screen and (min-width: 851px) and (max-width: 1245px) {
  .cart-size {
    width: 80% !important;
  }
}
@media screen and (min-width: 1250px) {
  .cart-size {
    width: 60% !important;
  }
}
@media screen and (min-width: 501px) and (max-width: 850px) {
  .order-container {
    flex-direction: column;
  }
  .cartproduct-container {
    width: 100%;
  }
  .order-details {
    width: 100%;
  }
  .cart-size {
    width: 100% !important;
  }
}
@media screen and (min-width: 200px) and (max-width: 500px) {
  .order-container {
    flex-direction: column;
  }
  .cartproduct-container {
    width: 100%;
  }
  .order-details {
    width: 100%;
  }
  .cart-size {
    width: 100% !important;
  }
}
