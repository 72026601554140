.refill-div {
    color: linear-gradient(104deg, rgb(139, 41, 242) 25%, rgb(32, 111, 255));
    border: none;
    width: 40%;
    margin-top: 5%;
    border-radius: 4px;
    padding: 1%;
  }

  .refill-btn2 {
    background: linear-gradient(104deg, rgb(139, 41, 242) 25%, rgb(32, 111, 255));
    color: white;
    border: none;
    width: 75px;
    border-radius: 4px;
  }