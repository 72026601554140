.main-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.main-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
}

@media screen and (max-width: 1245px) {
  .main-box {
    width: 90%;
  }
  .main-container {
    width: 90%;
  }
}
